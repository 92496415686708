import React, { memo } from 'react'

function ArticleTags({ tags }) {
  return (
    tags &&
    tags.length > 0 && (
      <div className="pb-05__gp">
        {tags.map((tag, i) => (
          <span key={tag} className="bold text-sm uppercase">
            {`${tag}${i < tags.length - 1 && tags.length > 1 ? ', ' : ' '}`}
          </span>
        ))}
      </div>
    )
  )
}

export default memo(ArticleTags)
