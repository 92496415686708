import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/SEO'
import { union } from 'lodash'
import TagLinks from '../components/Articles/TagLinks'
import ArticleTags from '../components/Articles/ArticleTags'
import LearnCenterLinks from '../components/Articles/LearnCenterLinks'

const LearnCenterPage = ({
  data: {
    content: { pageTitle, description, metaDescription, articles }
  },
  location
}) => {
  const [selectedTag, setSelectedTag] = useState('all')

  const tags = articles
    ?.reduce((acc, item) => {
      acc = union(acc, item.tags)
      return acc
    }, [])
    .sort((a, b) => a - b)
    .map((e) => e.toLowerCase())

  return (
    <div className="tracking-wider bg-grey-200">
      <SEO
        title={pageTitle}
        description={metaDescription}
        location={location}
      />
      <div className="flex flex-col items-center justify-center p-2 pb-6 mb-8 text-center bg-purple-50">
        <div className="p-4 text-4xl font-bold text-primary-base">
          {pageTitle}
        </div>
        <div className="flex flex-row max-w-3xl px-6 text-lg leading-relaxed text-center">
          {description}
        </div>
      </div>
      <TagLinks
        tags={tags}
        onSelect={(value) => setSelectedTag(value)}
        selectedTag={selectedTag}
      />
      <LearnCenterLinks location={location} />
      <div className="mx-auto leading-snug md:w-11/12">
        {articles
          ?.filter((e) => {
            if (selectedTag && selectedTag !== 'all') {
              return (
                e.tags &&
                e.tags.map((e) => e.toLowerCase()).includes(selectedTag)
              )
            }
            return true
          })
          .map((article) => {
            if (!article.slug) return null

            const slug = `/${article.slug.toLowerCase()}`
            return (
              <div key={article.id}>
                <div className="flex flex-col justify-center px-8 md:flex-row md:items-center items-left md:pt-0">
                  {article.image && article.image.fluid && (
                    <Link to={slug} className="md:p-6 md:w-2/5">
                      <Img
                        fluid={article.image.fluid}
                        alt={article.image.title}
                      />
                    </Link>
                  )}
                  <div className="py-6 md:w-2/5 md:p-6">
                    <ArticleTags tags={article.tags} />
                    <Link to={slug} className="text-2xl font-bold md:py-2">
                      {article.title}
                    </Link>
                    <div className="py-4 pb-8 text-lg tracking-normal">
                      {article.description}
                    </div>
                    <div className="w-3/4 md:w-1/2">
                      <Link to={slug} className={'bold'}>
                        {article.__typename === 'ContentfulLearnCenter'
                          ? 'Learn More'
                          : 'Read this article'}
                      </Link>
                    </div>
                  </div>
                </div>
                <hr className="w-10/12 py-2 mx-auto md:w-3/4 border-grey-557" />
              </div>
            )
          })}
      </div>
    </div>
  )
}

export const query = graphql`
  query LearnCenterPageQuery($id: String!) {
    content: contentfulLearnCenter(id: { eq: $id }, node_locale: { eq: "en-US" }) {
      name
      pageTitle
      description
      metaDescription
      articles {
        ... on ContentfulPage {
          title: pageTitle
          id
          description: metaDescription
          slug
          image: metaImage {
            fluid(maxWidth: 560, maxHeight: 338, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            id
            title
          }
          tags
        }
        ... on ContentfulArticle {
          title
          id
          description
          slug
          image: heroImage {
            fluid(maxWidth: 560, maxHeight: 338, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            id
            title
          }
          tags
        }
        ... on ContentfulLearnCenter {
          title: pageTitle
          id
          description
          slug
          image: heroImage {
            fluid(maxWidth: 560, maxHeight: 338, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            id
            title
          }
          tags
        }
      }
    }
  }
`

export default LearnCenterPage
