import React, { memo, useCallback } from 'react'
import classNames from 'classnames'

function TagLinks({ tags, onSelect, selectedTag }) {
  const onSelectHandler = useCallback(
    (value) => {
      if (onSelect) {
        onSelect(value)
      }
    },
    [onSelect]
  )

  return (
    tags &&
    tags.length > 0 && (
      <div>
        <div className="text-lg bold px-1__gp pb-3__gp whitespace-no-wrap overflow-scroll no-scrollbar w-11/12 md:w-4/5 mx-auto">
          {[...['all'], ...tags].map((tag) => {
            const classname = classNames(
              'px-1__gp cursor-pointer inline-block uppercase',
              {
                'text-black':
                  selectedTag === tag || (!selectedTag && tag === 'all'),
                'text-grey-667':
                  selectedTag !== tag && !(!selectedTag && tag === 'all')
              }
            )
            return (
              <div
                className={classname}
                key={tag}
                onClick={onSelectHandler.bind(null, tag)}
              >
                {tag}
              </div>
            )
          })}
        </div>
        <hr className="mx-auto pt-2__gp pb-4__gp w-11/12 md:w-4/5 border-grey-557" />
      </div>
    )
  )
}

export default memo(TagLinks)
