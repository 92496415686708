import React, { memo } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import classNames from 'classnames'

function LearnCenterLinks({ location = {} }) {
  const { content: { nodes: links } = {} } = useStaticQuery(query)
  const { pathname } = location

  return (
    links &&
    links.length > 0 && (
      <div>
        <div className="w-11/12 mx-auto overflow-scroll text-lg whitespace-no-wrap bold px-1__gp pb-3__gp no-scrollbar md:w-4/5">
          {links.map((link) => {
            const { id, name, slug } = link
            const activeLink = pathname === `/${slug.toLowerCase()}`
            const classname = classNames(
              'px-2__gp cursor-pointer inline-block uppercase',
              {
                'text-black': activeLink,
                'text-grey-667': !activeLink
              }
            )
            return (
              <Link
                to={`/${slug.toLowerCase()}`}
                className={classname}
                key={id}
              >
                {name}
              </Link>
            )
          })}
        </div>
        <hr className="w-11/12 mx-auto pt-2__gp pb-4__gp md:w-4/5 border-grey-557" />
      </div>
    )
  )
}

export default memo(LearnCenterLinks)

export const query = graphql`
  query LearnCenterLinksQuery {
    content: allContentfulLearnCenter(
      filter: { navigationVisible: { eq: true }, node_locale: { eq: "en-US" } }
    ) {
      nodes {
        id
        name
        slug
      }
    }
  }
`
